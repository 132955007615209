import React, { useRef, useEffect, useContext } from "react";
import d3 from "utils/d3Importer";
import { find, groupBy, propEq } from "ramda";
import { min, max, eachDayOfInterval, format, parseISO } from "date-fns";
import { isNull } from "utils/helper";
import { ThemeContext } from "context/theme-context";

export default function Timeline({
  incidents,
  lang = "en",
  width,
  height,
  isLoading,
}) {
  let svgEl = useRef(null);
  const data = [];
  const formatDate = d3.timeParse("%Y-%m-%d");
  const { colors } = useContext(ThemeContext);
  useEffect(() => {
    isLoading || isNull(incidents) || drawChart();
  }, [isLoading, incidents]);

  function drawChart() {
    const isEnglish = lang == "en";
    let dates = [];
    const units = groupBy((value) => value?.date)(incidents);
    for (const unit in units) {
      if (unit && unit !== "undefined") {
        data.push({
          date: formatDate(format(new Date(unit), "yyyy-MM-dd")),
          counts: Object.values(units[unit]).length,
          incidents: Object.values(units[unit]),
        });
        dates.push(formatDate(format(new Date(unit), "yyyy-MM-dd")));
      }
    }
    // add dates which don't have incidents

    const startDate = min(dates);
    const endDate = max(dates);
    console.log("startDate: ", startDate);
    console.log("endDate: ", endDate);
    dates = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
    const margin = { top: 5, right: 20, bottom: 30, left: 20 };
    const svgWidth = width - margin.right - margin.left;
    const svgHeight = height - margin.top - margin.bottom;

    const x = d3.scaleTime();
    isEnglish ? x.range([0, svgWidth]) : x.range([svgWidth, 0]);

    const y = d3.scaleLinear().range([svgHeight, 0]);

    const line = d3
      .line()
      .x((d) => x(d.date))
      .y((d) => y(d.counts))
      .curve(d3.curveLinear);

    const xAxis = d3
      .axisBottom(x)
      .ticks(d3.timeYear.every(1))
      .tickFormat(d3.timeFormat("%Y"));

    const yAxis = isEnglish
      ? d3.axisLeft(y).ticks(4)
      : d3.axisRight(y).ticks(4);

    const chart = d3
      .select(svgEl.current)
      .attr("width", svgWidth + margin.left + margin.right)
      .attr("height", svgHeight + margin.top + margin.bottom);

    d3.select("g").remove(); // remove any exists "g" elements fist, so rerenders work perfect.

    const g = chart
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    x.domain(d3.extent(data, (d) => d.date));
    y.domain([0, d3.max(data, (d) => d.counts)]);

    const newData = dates.map(
      (d) => find(propEq("date", d))(data) || { date: d, counts: 0 }
    );

    g.append("g")
      .attr("transform", "translate(0," + svgHeight + ")")
      .call(xAxis)
      .select(".domain");

    const t = isEnglish
      ? g.append("g")
      : g.append("g").attr("transform", "translate(" + svgWidth + ", 0)");
    t.call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em");

    g.append("path")
      .data([data])
      .attr("fill", "none")
      .attr("stroke", colors.primary)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-width", 1)
      .attr("d", line(newData));
  }
  return <svg ref={svgEl} width={500} height={500}></svg>;
}
